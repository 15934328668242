<template>
    <a-layout-content class="Category">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">
            <a-layout style="padding: 24px 0; background: #fff">
                <a-layout-sider width="400" style="background: #fff; border-right: 1px solid #eeeeee; padding-right: 10px">
                    <div class="content-header-left">
                        <h2>Sắp xếp danh mục</h2>
                    </div>
                    <a-divider/>
                    <nested-category :categories="parentCategories" />
                    <div class="cate_action">
                        <a-button @click="handleResetParentCate">Reset</a-button>
                        <a-popconfirm title='Bạn có chắc chắn?' @confirm="saveAllCategories">
                            <a-button type="primary">Cập nhật</a-button>
                        </a-popconfirm>
                    </div>
                </a-layout-sider>
                <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
                    <div class="content-header">
                        <h2>{{ this.$route.name }}</h2>
                        <div class="content-header__left">
                            <a-button type="primary" @click="openModal" icon="plus-circle">Thêm danh mục</a-button>

                            <a-input-search
                                    placeholder="Tìm kiếm..."
                                    style="width: 200px; margin-left: 30px"
                                    @change="(e) => this.search = e.target.value"
                            />
                        </div>
                    </div>
                    <a-divider />
                    <!--     Table question group       -->
                    <a-table :columns="columns" :dataSource="groupFilter" bordered :rowKey="(record, index) => record.id" :loading="loading"
                             :pagination="true">
                        <template slot-scope="text">
                            <div :key="col">
                                <template>{{text}}</template>
                            </div>
                        </template>

                        <template v-for="col in ['code', 'name']" :slot="col" slot-scope="text, record">
                            <div :key="col">
                                <a-input
                                        v-if="record.editable"
                                        style="margin: -5px 0"
                                        :value="text"
                                        @change="e => handleChange(e.target.value, record.id, col)"
                                />
                                <template v-else>{{text}}</template>
                            </div>
                        </template>

                        <template slot="featuredImage" slot-scope="text">
                            <a-avatar shape="square" size="large" :src="text.url" />
                        </template>

                        <template slot="active" slot-scope="text, record">
                            <a-popconfirm title='Bạn có chắc chắn?' @confirm="() =>  publishChange(record)">
                                <a-checkbox :checked="record.active"/>
                            </a-popconfirm>
                        </template>
                        <template slot="operation" slot-scope="text, record">
                            <div class='editable-row-operations'>
                                <span v-if="!record.editable"  style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                                  <a-button @click="() => edit(record.id)">Cập nhật</a-button>
                                </span>
                                <span v-if="record.editable" style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                                    <a-popconfirm title='Bạn có chắc chắn?' @confirm="() => saveGroup(record)">
                                        <a-button type="primary" style="display: inline-block; margin-bottom: 10px">Lưu lại</a-button>
                                    </a-popconfirm>
                                    <a-popconfirm title='Bạn có chắc chắn hủy?' @confirm="() => cancel(record.id)">
                                        <a-button  style="margin-left: 10px">Hủy</a-button>
                                    </a-popconfirm>
                                </span>
                                <span v-else style="margin-left: 10px; display: inline-block; margin-bottom: 10px;" />
                            </div>
                        </template>
                    </a-table>
                </a-layout-content>
            </a-layout>

        </div>

        <!--    Modal add new    -->
        <category-form
                ref="categoryForm"
                :visible="visible"
                @cancel="handleCancel"
                @create="handleCreate"
        />
    </a-layout-content>
</template>

<script>
    import './_Category.scss'
    import CategoryForm from "@/components/forms/CategoryForm";
    import { RepositoryFactory } from "@/repository/RepositoryFactory";
    import NestedCategory from "../../components/dd/NestedCategory";
    const categoryRepository = RepositoryFactory.get('category');

    const columns = [{
        title: 'Id',
        dataIndex: 'id',
        width: '5%',
        scopedSlots: { customRender: 'id' },
    }, {
        title: 'Hình đại diện',
        dataIndex: 'featuredImage',
        width: '15%',
        scopedSlots: { customRender: 'featuredImage' },
    }, {
        title: 'Tên viết tắt',
        dataIndex: 'code',
        width: '10%',
        scopedSlots: { customRender: 'code' },
    }, {
        title: 'Tên nhóm',
        dataIndex: 'name',
        width: '45%',
        scopedSlots: { customRender: 'name' },
    }, {
        title: 'Active',
        dataIndex: 'active',
        width: '5%',
        scopedSlots: { customRender: 'active' },
    }, {
        title: 'Hành động',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
    }];

    export default {
        components: { CategoryForm, NestedCategory },
        data() {
            return {
                visible: false,
                columns,
                data: [],
                parentCategories: [],
                cacheParentCategories: [],
                loading: true,
                cacheData: [],
                search: ''
            }
        },
        methods: {
            handleCancel() {
                this.visible = false;
            },
            handleCreate() {
                this.loading = true;
                const form = this.$refs.categoryForm.form;
                form.validateFields(async (err, values) => {
                    if (err) {
                        return;
                    }

                    try {
                        values.featuredImage = JSON.parse(values.featuredImage);
                        values.childCategories = [];

                        let { data } = await categoryRepository.addCategory(values);
                        if (!data.meta.success) throw new Error(data.meta.externalMessage);
                        this.data.push(data.data);
                        this.cacheData.push(data.data);

                        this.parentCategories.push(data.data);
                        this.cacheParentCategories.push(data.data);
                        form.resetFields();
                        this.$refs.categoryForm.removeImageSelected();
                        this.visible = false;
                    } catch (e) {
                        this.openNotification("Tạo mới danh mục bị lỗi! Vui lòng liên hệ nhóm phát triển!")
                    } finally {
                        this.loading = false;
                    }
                });
            },
            openModal() {
                this.visible = true;
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
            edit(id) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target.editable = true;
                    this.data = newData
                }
            },
            handleChange (value, id, column) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.data = newData;
                }
            },
            handleChangeOnActive (value, id, column) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.data = newData;
                    this.cacheData = newData.map(item => ({...item}))
                }
            },
            async saveGroup (record) {
                try {
                    this.loading = true;

                    let response = await categoryRepository.updateCategory(record.id, record);
                    if (!response.data.meta.success) throw new Error(response.data.meta.externalMessage)
                    const newData = [...this.data];
                    const target = newData.filter(item => record.id === item.id)[0];

                    this.deepUpdateCategory(this.parentCategories, response.data.data);
                    this.cacheParentCategories = JSON.parse(JSON.stringify(this.parentCategories));

                    if (target) {
                        delete target.editable;
                        this.data = newData;
                        this.cacheData = newData.map(item => ({ ...item }))
                    }
                } catch (e) {
                    this.openNotification(e.message);
                } finally {
                    this.loading = false;
                }
            },
            async saveAllCategories () {
                try {
                    this.loading = true;
                    let response = await categoryRepository.updateAllCategories(this.deepUpdatePriorityCategory(this.parentCategories));
                    if (!response.data.meta.success) throw new Error(response.data.meta.externalMessage);
                    await this.fetchAllParentCategories();
                    this.fetchAllCategories();
                } catch (e) {
                    this.openNotification(e.message);
                } finally {
                    this.loading = false;
                }
            },

            handleResetParentCate() {
                this.parentCategories = JSON.parse(JSON.stringify(this.cacheParentCategories));
            },

            cancel (id) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    Object.assign(target, this.cacheData.filter(item => id === item.id)[0])
                    delete target.editable;
                    this.data = newData
                }
            },
            async deleteGroup(id) {
                try {
                    this.loading = true;
                    let response = await categoryRepository.deleteCategory(id);
                    if (!response.data.meta.success) {
                        throw new Error(response.data.meta.externalMessage);
                    }
                    this.data = this.data.filter(item => item.id !== id);
                    this.cacheData = this.cacheData.filter(item => item.id !== id);

                    this.parentCategories = this.deepDeleteCategory(this.parentCategories, id);
                    this.cacheParentCategories = this.deepDeleteCategory(this.cacheParentCategories, id);
                } catch (e) {
                    this.openNotification(e.message);
                } finally {
                    this.loading = false;
                }
            },
            async publishChange(record) {
                record.active = !record.active;
                await this.saveGroup(record);
                this.handleChangeOnActive(record.active, record.id, 'active')
            },
            async fetchAllCategories() {
                let response = await categoryRepository.getAll();
                // eslint-disable-next-line no-console
                if (!response.data.meta.success) {
                    this.data = [];
                    throw new Error(response.data.meta.externalMessage)
                }

                this.data = response.data.data;
                this.cacheData = this.data.map(item => ({...item}));
            },

            async fetchAllParentCategories() {
                let response = await categoryRepository.getAllParents();
                // eslint-disable-next-line no-console
                if (!response.data.meta.success) {
                    this.parentCategories = [];
                    throw new Error(response.data.meta.externalMessage)
                }

                this.parentCategories = response.data.data.sort((a, b) => a.priority - b.priority);
                this.cacheParentCategories = JSON.parse(JSON.stringify(this.parentCategories));
            },

            deepUpdateCategory(categories, newCategory) {
                categories.forEach(category => {
                    if (category.id === newCategory.id) {
                       Object.assign(category, newCategory);
                        // eslint-disable-next-line no-console
                       console.log(category);
                    } else {
                        this.deepUpdateCategory(category.childCategories, newCategory)
                    }
                });
            },

            deepDeleteCategory(categories, id) {
                categories = categories.filter(category => category.id !== id);
                categories.forEach(category => {
                    category.childCategories = this.deepDeleteCategory(category.childCategories, id);
                });
                return categories;
            },
            deepUpdatePriorityCategory(categories) {
                return categories.map((category, index) => {
                    category.priority = index + 1;
                    category.childCategories = this.deepUpdatePriorityCategory(category.childCategories);
                    return category;
                })
            }
        },
        computed: {
            groupFilter: function () {
                return this.data.map(item => Object.assign({}, item))
                    .filter(item => {
                        return item.code.toUpperCase().includes(this.search.toUpperCase()) || item.name.toUpperCase().includes(this.search.toUpperCase())
                    })
            }
        },
        async created() {
            try {
                this.loading = true;
                this.fetchAllCategories();
                this.fetchAllParentCategories();
            } catch (e) {
                this.openNotification(e.message)
            } finally {
                this.loading = false;
            }
        }
    };
</script>